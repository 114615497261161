import React from 'react'

export default function ResourcePreview({ resource }) {
  return (
    <>
      <div className="resource-details">
        <h6 className="bkd-near-black">
          <span className="pad-sides">{resource.category}</span>
        </h6>
        <h4 className="pad-sides">{resource.title}</h4>
        <div
          className="description pad-sides font-size-smaller"
          dangerouslySetInnerHTML={{
            __html: resource.description.childMarkdownRemark.html,
          }}
        />
      </div>
      <a className="button" href={`https:${resource.file.file.url}`}>
        Download
      </a>
    </>
  )
}

import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import * as styles from './top-hero.module.css'

export default function TopHero({ heroImage, heroTitle }) {
  return (
    <section className="top-social">
      <div className={styles.hero}>
        <header>
          <h1 className="hero-title">{heroTitle}</h1>
        </header>
        <div className={styles.heroImageWrap}>
          <GatsbyImage image={heroImage} className={styles.heroImage} alt="" />
        </div>
      </div>
    </section>
  )
}

import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import TopHero from '../components/top-hero'
import ResourcePreview from '../components/resource-preview'

class ResourcesPage extends React.Component {
  render() {
    const resources = this.props.data.contentfulResourcesPage
    let sectionCount = 0
    return (
      <Layout location={this.props.location}>
        <Helmet title={resources.title} />
        <TopHero
          heroImage={resources.heroImage.gatsbyImageData}
          heroTitle={resources.heroTitle}
        />
        <section>
          {resources.sections.map((section) => {
            return (
              <div>
                <header>
                  <p className="section-number">0{(sectionCount += 1)}</p>
                  <h2 id={section.slug}>{section.title}</h2>
                </header>
                {section.description && (
                  <div
                    className="mt section-description"
                    dangerouslySetInnerHTML={{
                      __html: section.description.childMarkdownRemark.html,
                    }}
                  />
                )}
                <div className="section-indent">
                  <ul className="book-list resources">
                    {section.resources.map((resource) => {
                      return (
                        <li key={resource.id} className="resource">
                          <ResourcePreview resource={resource} />
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            )
          })}
        </section>
      </Layout>
    )
  }
}

export default ResourcesPage

export const pageQuery = graphql`
  query ResourcesPageQuery {
    contentfulResourcesPage(
      id: { eq: "c9184f58-7287-5430-a851-60a5c42c1318" }
    ) {
      heroTitle
      heroImage {
        gatsbyImageData(width: 1122, layout: CONSTRAINED)
      }
      sections {
        description {
          childMarkdownRemark {
            html
          }
        }
        id
        resources {
          category
          description {
            childMarkdownRemark {
              html
            }
          }
          file {
            file {
              url
            }
          }
          id
          title
        }
        slug
        title
      }
      title
    }
  }
`
